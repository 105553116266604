@import "../../_theme_variables";

#product_comments_block_extra {
	font-weight: 400;
	overflow:hidden;
	margin: 10px 0 10px;
	.comments_note {
		float:left;
		margin-bottom:5px;
		margin-right:15px;
		border-right:1px solid $base-border-color;
		padding:1px 15px 1px 0;
	}	
	.comments_note span,
	.star_content {
		float: left;
		color: $base-text-color;
	}	
	div.star {
		&:after {
			content: "\f005";
			font-family: $font-icon;
			display: inline-block;
			color: #dddddd;
		}
	}
	div.star_on {
		&:after {
			content:"\f005";
			font-family: $font-icon;
			display: inline-block;
			color: #333;	
		}
	}	
	.comments_advices {
		
	}
}

.comments_advices {
	margin:0 0 0 0;
	li{
		display:inline-block;
		border-left:1px solid $base-border-color;
		padding:0 10px;
		margin-bottom:5px;
		&:first-child {
			border:none;
			padding-left:0;
		}
	}
	a {  
		text-decoration: none;
		position:relative;	
		margin-right: 0px;
		padding: 0;
		display:inline-block;	
	}
}


/* pop-in add grade/advice ********************************************************************* */
#fancybox-wrap {
	width: 585px; 
}
#fancybox-content {
	width: 585px;
	border-width: 0;
}
#new_comment_form {
	overflow: hidden;
	color: $base-text-color;
	text-align: left;	
	h2{
		margin-bottom: 10px;
	}			
	.title {
		padding: 10px;
		font-size: 13px;
		color: $light-text-color;
		text-transform: uppercase;
		background: $dark-background;
	}
	ul.grade_content {
		list-style-type: none;
		margin: 0 0 20px 0;
		li{
			width: 50%;
		}
		span{
			display:inline-block;
			padding: 0 10px;
			width: 150px;
			font-weight: bold;
		}
		.cancel {
			margin-right: 5px;
		}	
	}			 
	.product {
		padding: 15px;
		img{
			border: $main_border;
		}
		.product_desc {									
			line-height: 18px;
			color: $description-color;
			.product_name {
				padding: 10px 0 5px;
				font-size: 13px;
				color: #000;
			}
		}
	} 	
	.new_comment_form_content {
		padding: 15px 25px 15px 15px;
		background: #f8f8f8;
		@media (max-width: $screen-xs-max) { //max 767px
			padding-left:25px;
		}
		.intro_form {
			padding-bottom: 10px;
			font-weight: bold;
			font-size: 12px;
		}
	}			
	label {
		display: block;
		margin: 12px 0 4px 0;
		font-weight: bold;
		font-size: 12px;
	}
	input,
	textarea  {
		padding: 0 5px;
		height: 28px;
		width: 100%;
		border: 1px solid #ccc;
		background: $light-background;
	}
	textarea {		
		height: 80px;		
	}
	.submit {
		margin-top: 20px;
		padding: 0;
		font-size: 13px;
		text-align: right;
	}

	#criterions_list {
		border-bottom: 1px solid #CCC;
		padding-bottom: 15px;
		list-style-type: none;
		li{
			margin-bottom: 10px;
		}
		label {
			display: inline;
			float: left;
			margin: 0 10px 0 0;
		}
		.star_content {
			float: left;
		}
	}
	#new_comment_form_footer {
		margin-top: 20px;
		font-size: 12px;
	}
}
/* TAB COMMENTS ******************************************************************************** */
#product_comments_block_tab {
	margin: 0 0 20px 0;
	div.comment {
		margin: 0 0 14px;
		padding-top: 14px;		
		border-top: 1px solid $base-border-color;
		&:first-child{
			padding: 0;
			border: none;
		}
		.comment_author {		
			padding: 0;
			line-height: 18px;
			span{
				font-weight: bold;
				float: left;
			}
			.star_content {
				display: inline-block;
				vertical-align: middle;
				margin: 0 0 0 5px;
			}
		}
		.comment_details {
			overflow: hidden;
			border-left: $main_border;	
			@media (max-width: $screen-sm-max) { //max 767px
				border-left: none;
				ul {
					list-style-type: none;
					margin: 0;
					li {
						padding: 2px 0 2px;
						&:before{
							font-family: $font-icon;	
							content: "\f0da";	
							color: $base-text-color;
							margin-right: 8px;
						}
					}
				}					
			}
		}			
	}
	div.star {
		&:after {
			content: "\f006";
			font-family: $font-icon;
			display: inline-block;
			color: #777676;	
		} 
	}
	div.star_on {
		&:after {
			content: "\f005";
			font-family: $font-icon;
			display: inline-block;
			color: #ef8743;	
		}
	}
	.comment_author_infos {
		clear: both;
		padding-top: 9px;
		strong{
			display: block;
			color: $base-text-color;
		}
		em {
			color: #adadad;
		}
	}	
	a {
		text-decoration: none;
		font-weight: bold;	
	}

	span.report_btn {
		cursor: pointer;
	}
	span.report_btn:hover {
		text-decoration: underline;
	}
}
.fl {
	float: left;
}
.fr {
	float: right;
}
.submit-button {
	button.button {
			
	}
}